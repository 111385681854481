.contact-list {
	padding-top: 0;
	max-width: 1160px;

	.reg-divider {
		margin-bottom: 50px;
	}
}

.contact-card {
	flex-direction: row;
	flex-wrap: wrap;
	padding: 5px 10px;
	padding-bottom: 60px;
	width: 33.33%;

	@include below($l) {
		width: 33.33%;
	}

	@include below($m) {
		width: 50%;
	}

	@include below($s) {
		width: 100%;
		padding: 0;
		padding-bottom: 40px;
	}

	&.col-3 {
		width: 33.33%;
	}
}

.contact-card-top {
	position: relative;
	width: 100%;

	img {
		width: 100%;
		display: block;
		margin-bottom: 10px;
	}

	&:hover {
		.round-btn {
			background: lighten($orange, 10%);
		}
	}
}

.contact-card-bottom {
	width: 100%;
	height: 100%;

	p {
		font-family: $monoFont;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 1px;

		&.c-u {
			text-transform: uppercase;
		}

		a {
			text-decoration: none;
			font-weight: 600;
			color: $darkBlue;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.filling {
	height: 18px;
	display: block;
}

.round-btn {
	width: 44px;
	height: 44px;
	border-radius: 100px;
	background: $orange;
	position: absolute;
	bottom: 20px;
	right: 10px;
	text-align: center;
	transition: background .2s ease;

	span {
		@include vertical-align(absolute);
		line-height: 44px;
		display: block;
		width: 100%;
		color: $white;
		font-weight: 600;
		font-size: 18px;
		margin-top: -1px;
	}
}

.contact-about {
	p {
		font-family: $monoFont;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 1px;

		&.c-u {
			text-transform: uppercase;
		}

		a {
			text-decoration: none;
			font-weight: 600;
			color: $darkBlue;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
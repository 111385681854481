.intro-section {
	width: 100%;
	max-width: 1220px;
	//max-width: 1580px;
	margin: 0 auto;
	padding: 60px 0;
	display: block;
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 3px;
		background: $lightBlue;
		position: absolute;
		bottom: 0;
		display: none;
	}

	.intro-left {
		float: left;
		padding: 0 40px;
		width: 30%;

		.uppercase {
			margin-bottom: 40px;
			display: block;
		}

		@include below($bigBreaker) {
			padding-left: 0;
		}

		@include below($m) {
			width: 100%;
			margin-bottom: 40px;
			padding: 0;
		}
	}

	.intro-right {
		float: left;
		padding: 0 40px;
		width: 70%;

		@include below($bigBreaker) {
			padding-right: 0;
		}

		@include below($m) {
			width: 100%;
			padding: 0;
		}
	}
}
.intro-section-grid {
	width: 100%;
	max-width: 1240px;
	//max-width: 1600px;
	margin: 0 auto;
	padding: 60px 0 50px;
	display: block;
	position: relative;

	.intro-left {
		float: left;
		padding: 0 40px 0 50px;
		width: 30%;

		@include below($bigBreaker) {
			padding-left: 0;
		}

		@include below($m) {
			width: 100%;
			text-align: center;
			padding-right: 0;

			.btn {
				display: inline-block;
				margin-bottom: 40px;
			}
		}

		.title-l {
			margin-bottom: 40px;
		}

		.uppercase {
			margin-bottom: 40px;
			display: block;
		}
	}

	.intro-right {
		float: left;
		padding: 0 40px 0 45px;
		width: 70%;

		@include below($bigBreaker) {
			padding-right: 0;
		}

		@include below($m) {
			width: 100%;
			padding-left: 0;
		}
	}

	.card-box-logo {
		width: 33.33%;
		float: left;
		display: block;
		padding-right: 10px;
		padding-bottom: 10px;

		@include below($s) {
			width: 100%;
			padding-right: 0;
		}

		.card-box-logo-inner {
			background: #F4F4F4;
			display: block;
			height: 142px;
			padding: 20px;
			min-height: 200px;

			@include below($s) {
				min-height: 0;
			}

			img {
				@include align-both();
				display: block;
				max-height: 100%;
				max-height: 130px;
				max-width: 100%;

				@include below($s) {
					padding: 10px;
				}
			}
		}
	}
}
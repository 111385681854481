.article-holder {
	display: block;
	width: 100%;
	max-width: $contentMaxWidth;
	//max-width: 1500px;
	margin: 0 auto;
	padding-bottom: 60px;
	//border-bottom: 3px solid $darkBlue;

	&.contact-article {
		padding-bottom: 0;
	}

	&.lower-p {
		padding-bottom: 20px;
	}
}

.privacy-article {
	.article-content {
		//margin: 0 auto;
		//float: none;
	}
}

.sidebar {
	width: 30%;
	float: left;
	padding-right: 80px;

	.uppercase {
		display: block;
		margin-bottom: 40px;
	}

	.reg-divider {
		margin-bottom: 40px;
	}

	@include below($m) {
		width: 100%;
		float: none;
		padding-right: 0;

		&.low-c {
			text-align: center;
			border-bottom: 3px solid $lightBlue;
			padding-bottom: 40px;
		}

		.low-c {
			text-align: center;
		}

		.btn {
			display: inline-block;
		}
	}
}

.sidebar-box {
	margin-bottom: 120px;

	.excerpt-p {
		margin-bottom: 40px;
	}

	&.no-marg {
		margin-bottom: 0;
	}

	@include below($m) {
		margin-bottom: 60px;
	}
}

.article-content {
	width: 70%;
	float: right;

	@include below($m) {
		width: 100%;
		float: none;
	}

	.excerpt-p,
	.excerpt-p p {
		margin: 40px 0;
	}

	.regular-p,
	.regular-p p {
		margin: 40px 0;
	}

	img {
		width: 100%;
		height: auto;
		display: block;
	}

	.reg-divider {
		margin-bottom: 40px;
	}

	p a {
		color: $darkBlue;
	}
}

// Services listing
.services-holder {
	width: 100%;
	max-width: $contentMaxWidth + 20px;
	max-width: $contentMaxWidth;
	//max-width: 1500px;
	margin: 0 auto;
	display: block;
}

.services-holder-inner {
	float: right;
	display: block;
	width: 70%;
	display: flex;
	flex-wrap: wrap;

	@include below($m) {
		width: 100%;
		margin-top: 20px;
		float: none;
	}

	.card-box-logo {
		width: 33.33%;
		float: left;
		display: block;
		padding-right: 10px;
		padding-bottom: 10px;
		//margin-bottom: 40px;

		&.cbl-flex {
			display: flex;
		}

		@include below($s) {
			width: 100%;
			padding-right: 0;
		}

		.card-box-logo-inner {
			text-decoration: none;
			display: block;
			width: 100%;
			transition: opacity .2s ease;

			&:hover {
				opacity: .8;
			}
		}

		.card-box-top {
			background: $lightBlue;
			display: block;
			height: 142px;
			padding: 20px;
			position: relative;
			text-align: center;
			//min-height: 200px;

			@include below($s) {
				min-height: 0;

				img {
					padding: 10px;
				}
			}

			&.bg-gray {
				background: $lightGray;
			}

			img {
				@include align-both();
				display: block;
				max-height: 100%;
			}

			h3 {
				color: $black;
				text-decoration: none;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				font-size: 18px;
				line-height: 27px;
				@include align-both(absolute);
				font-family: $titleFont;
				font-weight: 600;
			}
		}

		.service-title {
			color: $black;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			font-size: 18px;
			line-height: 27px;
			font-family: $titleFont;
			font-weight: 600;
			display: block;
			text-align: center;
			padding: 10px 0;
			color: $white;
			background: $darkBlue;
			padding: 10px;
			min-height: 76px;
			position: relative;

			span {
				@include vertical-align(absolute);
				width: 100%;
				padding: 10px;
				left: 0;
			}
		}

		.btn {
			background: $darkBlue;
			text-decoration: none;
			max-width: 100%;
		}
	}
}

.cbl-sidebar {
	width: 100%;
	display: block;
	padding-top: 50px;

	.card-box-logo-inner {
		text-decoration: none;
		display: block;
	}

	.card-box-top {
		background: $lightBlue;
		display: block;
		height: 142px;
		padding: 20px;
		position: relative;
		text-align: center;

		&.bg-gray {
			background: $lightGray;
		}

		img {
			@include align-both();
			display: block;
			max-height: 100%;
		}

		h3 {
			color: $black;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			font-size: 18px;
			line-height: 27px;
			@include align-both(absolute);
			font-family: $titleFont;
			font-weight: 600;
		}
	}
}

.article-list-block {
	margin-top: 50px;
}

// Privacy policy
.privacy-wrapper {
	//padding-left: 320px;
}

ol.privacy-nav {
	counter-reset: item;
	padding: 0;
	pointer-events: auto;

	li {
		color: $darkBlue;
		font-family: $monoFont;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 1px;
		font-weight: 500;
		padding: 5px 0;
		display: block;
		position: relative;
		//padding-left: 20px;

		&.sub-nav-item {
			padding-left: 20px;
		}

		&:before { 
			content: counters(item, ".") ". "; 
			counter-increment: item;
			position: absolute;
			left: 0;
			display: none;
		}

		ol {
			padding-left: 20px;
		}

		li {
			padding-top: 10px;
			padding-bottom: 0;
			//padding-left: 34px;

			&:last-child {
				padding-bottom: 10px;
			}
		}

		ol {
			counter-reset: item;
		}

		ol > li:before {
			content: counters(item, ".") " ";
		}

		a {
			color: $darkBlue;
			text-decoration: none;
			transition: color .2s ease;

			&.active {
				color: $orange;
			}

			&:hover {
				color: darken($darkBlue, 10%);
			}
		}
	}
}

.chapter-p h2 {
	margin-bottom: 30px;
}

.chapter-p h3 {
	margin-bottom: -10px;
}

.sidebar-privacy {
	.sidebar-box {
		//margin-bottom: 0;
	}
}

#p-sidebar-holder.is-fixed {
	position: fixed;
	width: 100%;
	max-width: 1140px;
	pointer-events: none;
	top: 92px;

	/*@include below($m) {
		top: 92px;
		left: 0;
		padding: 40px 30px 0;
		background: $lightBlue;
		display: none;

		.reg-divider {
			display: none !important;
		}

		.sidebar-box {
			margin-bottom: 40px;
		}

		&.visible {
			display: block;
		}
	}

	@include below($s) {
		padding: 40px 20px 0;
	}*/
}

#p-sidebar-holder {
	@include below($m) {
		position: fixed;
		width: 100%;
		max-width: 1140px;
		pointer-events: none;
		top: 92px;
		left: 0;
		padding: 40px 30px 0;
		background: $lightBlue;
		display: none;

		.reg-divider {
			display: none !important;
		}

		.sidebar-box {
			margin-bottom: 40px;
		}

		&.visible {
			display: block;
		}
	}

	@include below($s) {
		padding: 40px 20px 0;
	}
}

.mobile-content-btn {
	position: fixed;
	top: 92px;
	left: 0;
	width: 100%;
	padding: 10px;
	text-align: right;
	background: $lightBlue;
	background: rgba(181, 213, 232, .7);
	cursor: pointer;

	@include above($m) {
		display: none;
	}
}

.sidebar-privacy.fixed {
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 132px;
	padding-left: 40px;
	padding-right: 40px;
	height: 100%;
	background: $white;
	max-width: 320px;
	background: $lightGray;
	background: $white;
}

// Cases and services
.case-logo-inner {
	.card-box-top {
		min-height: 200px;

		img.case-logo {
			max-height: 130px;
			max-width: 100%;
		}
	}
}

// CV
.cv-block {
	margin-top: 60px;

	h3 {
		margin: 0;
	}
}

.cv-row {
	padding: 20px 0;
	border-bottom: 3px solid $lightBlue;

	p {
		margin: 0 !important;
	}
}

// Stand out
.stand-out {
	padding: 30px;
	border: 3px solid $lightBlue;
	//background: $lightBlue;

	.btn {
		display: inline-block;
	}

	p {
		margin-top: 0 !important;
	}
}

.m-marg-b {
	@include below($m) {
		margin-bottom: 40px;
	}
}
.archive-filter {
	width: 100%;
	max-width: $contentMaxWidth;
	//max-width: 1500px;
	margin: 0 auto;
	padding: 40px 0 60px;
	border-top: 3px solid $lightBlue;

	.uppercase {
		margin-bottom: 20px;
		display: block;
	}
}

.archive-filter-holder {
	display: block;
}

.filter-btn {
	padding: 10px 20px;
	border: 1px solid $orange;
	color: $black;
	text-decoration: none;
	font-family: $monoFont;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	float: left;
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: background .2s ease, color .2s ease;

	&:hover,
	&.active {
		background: $orange;
		color: $white;
	}
}

// Link archive
.link-archive-section {
	margin-top: 40px !important;

	.list-boxes-holder {
		margin-bottom: 60px !important;
	}
}

.link-archive-title {
	display: block;
	padding: 0 5px;
	margin-bottom: 20px;

	@include below($s) {
		padding: 0;
	}
}
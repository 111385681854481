.ff-section {
	width: 100%;
	max-width: $contentMaxWidth;
	//max-width: 1500px;
	margin: 0 auto;
}

.ff-holder {
	display: block;
	position: relative;
	padding: 60px 0;
	display: flex;

	@include below($m) {
		display: block;
	}
}

.ff-text-col {
	width: 50%;
	float: left;
	position: relative;

	.ff-text-col-content {
		@include vertical-align(absolute);
		width: 100%;
		padding-right: 120px;

		.title-l {
			margin-bottom: 40px;
			max-width: 450px;

			@include below($m) {
				margin-left: auto;
				margin-right: auto;
			}
		}

		@include below($m) {
			@include no-align();
			text-align: center;
			padding: 0;

			.btn {
				display: inline-block;
			}
		}
	}

	@include below($m) {
		width: 100%;
		margin-bottom: 40px;
	}
}

.ff-image-col {
	width: 50%;
	float: left;
	position: relative;

	img {
		width: 100%;
		display: block;
	}

	@include below($m) {
		width: 100%;
	}
}

.overlay-small {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, .2);
}
.top-navigation {
	width: 100%;
	height: 92px;
	background: #fff;
	border-bottom: 1px solid #F4F4F4;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background: rgba(255, 255, 255, 1);
	//box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
	//box-shadow: 0 2px 4px rgba(0,0,0,.5)
}

.home-link {
	@include vertical-align(absolute);
	left: 50px;

	img {
		max-height: 50px;
		display: block;
	}

	@include below($m) {
		left: 30px;
	}

	@include below($s) {
		left: 20px;

		img {
			max-height: 44px;
		}
	}
}

.navigation-holder {
	@include vertical-align(absolute);
	right: 50px;

	@include below($m) {
		@include no-align();
		position: absolute;
		top: 0;
		right: 0;
		padding-top: 92px;
		background: $white;
		display: none;
		border-bottom: 1px solid #F4F4F4;

		&.visible {
			display: block;
		}
	}
}

.main-nav {
	list-style: none;
	float: left;

	li {
		float: left;
		padding: 0 14px;

		a {
			color: $black;
			color: $darkBlue;
			text-decoration: none;
			font-family: $monoFont;
			font-size: 14px;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-weight: 500;
			opacity: 1;
			transition: opacity .2s ease;
			position: relative;

			&:hover {
				opacity: .5;
			}
		}

		&.current-menu-item {
			a {
				opacity: .5;
			}
		}

		@include below($m) {
			float: none;
			display: block;
			text-align: right;
			padding: 10px 30px;
		}
	}

	@include below($m) {
		float: none;
		display: block;
	}
}

.lang-menu {
	list-style: none;
	float: right;
	margin-left: 28px;

	li {
		float: left;
		padding: 0 8px;

		a {
			color: $black;
			text-decoration: none;
			font-family: $monoFont;
			font-size: 14px;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-weight: 500;
			opacity: .5;

			&.active-lang {
				opacity: 1;
			}
		}

		@include below($m) {
			float: none;
			padding: 10px 30px;
		}
	}

	@include below($m) {
		float: none;
		display: block;
		margin-left: 0;
		text-align: right;
		margin-top: 20px;
		margin-bottom: 40px;
	}
}

.breadcrumbs-holder {
	width: 100%;
	height: 46px;
	background: $darkBlue;
}

// Mobile menu
.mobile-menu-btn {
	@include vertical-align(absolute);
	right: 30px;
	cursor: pointer;
	user-select: none;
	transition: background .2s ease;
	width: 18px;
	height: 100%;

	@include above($m) {
		display: none;
	}

	i {
		@include burger(18px, 3px, 2px, $darkBlue, 0, 0.3s);
		@include vertical-align(absolute);
		//top: 2px;
		left: 0;
		margin-top: 0;

		&:before {
			margin: 0;
		}
	}

	&.active {
		i {
			//position: absolute;
			//top: 2px;
			left: 0;
			@include burger-to-cross;

			&:before {
				margin: 0;
			}
		}
	}
}
@font-face {
  	font-family: 'IBM Plex Sans';
  	font-style: sans-serif;
}

@font-face {
  	font-family: 'IBM Plex Serif';
  	font-style: serif;
}

@font-face {
  	font-family: 'IBM Plex Mono';
  	font-style: monospace;
}

$titleFont: 'IBM Plex Sans';
$excerptFont: 'IBM Plex Serif';
$bodyFont: 'IBM Plex Sans';
$monoFont: 'IBM Plex Mono';

.title-xl {
	font-size: 50px;
	line-height: 60px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	font-family: $titleFont;

	@include below($s) {
		font-size: 30px;
		line-height: 40px;
	}
}

.title-l {
	font-size: 30px;
	line-height: 36px;
	letter-spacing: 1.5px;
	font-family: $titleFont;
	font-weight: 600;
	text-transform: uppercase;

	@include below($s) {
		font-size: 25px;
		line-height: 30px;
	}
}

.title-s {
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 1.5px;
	font-family: $titleFont;
	font-weight: 600;
	text-transform: uppercase;

	@include below($s) {
		font-size: 16px;
		line-height: 22px;
	}
}

.title-xs {
	font-size: 15px;
	line-height: 26px;
	letter-spacing: 1.2px;
	font-family: $titleFont;
	font-weight: 600;
	text-transform: uppercase;
}

.excerpt-p,
.excerpt-p p {
	font-family: $excerptFont;
	font-size: 20px;
	line-height: 38px;

	@include below($s) {
		font-size: 18px;
		line-height: 34px;
	}
}

.regular-p,
.regular-p p {
	font-family: $bodyFont;
	font-weight: 300;
	font-size: 18px;
	line-height: 30px;
}

.regular-p h2 {
	font-size: 26px;
	line-height: 32px;
	font-family: $titleFont;
	font-weight: 600;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	margin-top: 60px;
}

.regular-p h3 {
	font-weight: 600;
	margin-bottom: -30px;
}

.regular-p ul {
	list-style: none;
	padding-left: 20px;

	@include below($s) {
		padding-left: 0;
	}

	li {
		position: relative;
		padding-left: 30px;
		max-width: 500px;
		margin: 20px 0;

		a {
			color: $darkBlue;
		}
	}

	li:before {
		content: '';
		width: 8px;
		height: 8px;
		position: absolute;
		top: 11px;
		left: 5px;
		background: $orange;
		border-radius: 50px;

	}
}

.uppercase {
	font-size: 16px;
	line-height: 22px;
	font-family: $monoFont;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
.list-section-boxes {
	width: 100%;
	max-width: 1150px;
	//max-width: 1510px;
	margin: 0 auto;
	padding: 60px 0;

	&.archive-list {
		padding-top: 0;
		padding: 0;
	}

	.title-holder-l {
		padding: 0 5px;
		margin-bottom: 40px;
	}
}

.list-boxes-holder {
	display: block;
	display: flex;
	flex-wrap: wrap;
}

.box-card-outer {
	width: 25%;
	float: left;
	padding: 5px;
	display: flex;

	&.link-box {
		width: 50%;

		.date {
			white-space: nowrap; 
			overflow: hidden;
			text-overflow: ellipsis;
		}

		@include below($s) {
			width: 100%;
		}
	}

	&.sidebar-box-card {
		width: 100%;
		display: block;
		padding: 5px 0;
	}

	@include below($m) {
		width: 50%;
	}

	@include below($s) {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}

.box-card {
	background: $lightBlue;
	display: block;
	padding: 15px 20px 55px;
	text-decoration: none;
	position: relative;
	transition: background .2s .2s ease, bottom .2s ease;
	bottom: 0;
	width: 100%;
	//min-height: 220px;
	
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 3px;
		background: $darkBlue;
		position: absolute;
		bottom: 0;
		left: 0;
		transition: height .1s .1s ease;
	}

	.date {
		font-family: $monoFont;
		font-size: 12px;
		line-height: 18px;
		text-transform: uppercase;
		color: $darkBlue;
		letter-spacing: .5px;
		display: block;
		margin-bottom: 5px;
	}

	p {
		font-family: $excerptFont;
		font-size: 18px;
		line-height: 27px;
		color: $black;
	}

	&:hover {
		background: lighten($lightBlue, 2%);
		bottom: 3px;

		&:after {
			height: 9px;
		}
	}
}

.link-box .box-card {
	background: $white;
	border: 1px solid $lightBlue;
	border-bottom: 0;
}
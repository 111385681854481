@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$contentWidth: 100%;
$contentPadding: 40px;
$contentMaxWidth: 1140px;
$contentGridWidth: 1220px;
$bigBreaker: 1222px;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$black: #000000;
$white: #FFFFFF;
$orange: #E74C05;
$lightBlue: #B5D5E8;
$darkBlue: #006B98;
$lightGray: #F4F4F4;

@import 'fonts';

// Color styles
.c-orange {
	color: $orange;
}

.c-black {
	color: $black;
}

.c-darkblue {
	color: $darkBlue;
}

.bg-lightblue {
	background-color: $lightBlue;
}

.bg-darkblue {
	background-color: $darkBlue;
}

.bg-orange {
	background-color: $orange;
}

.bg-lightgray {
	background-color: $lightGray;
}

// Defaults
body {
	padding-top: 92px;
	//padding-left: 20px;
	//padding-right: 20px;
}

.main {
	@include below($bigBreaker) {
		padding: 0 50px;
	}

	@include below($m) {
		padding: 0 30px;

		&.privacy-main {
			padding-top: 60px;
		}
	}

	@include below($s) {
		padding: 0 20px;
	}
}

// Buttons
.btn {
	background: $orange;
	color: $white;
	padding: 22px 30px;
	width: 100%;
	max-width: 264px;
	font-family: $monoFont;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	display: block;
	transition: background .3s .1s ease;

	&:hover {
		background: lighten($orange, 4%);
	}
}

.btn-darkblue {
	background: $darkBlue;

	&:hover {
		background: lighten($darkBlue, 4%);
	}
}

.btn-holder {
	display: block;
	text-align: center;
	margin-top: 50px;

	.btn {
		display: inline-block;
	}
}

// Dividers
.reg-divider {
	border: 0;
	height: 3px;
	background: $lightBlue;
	display: block;
	width: 100%;
	max-width: $contentMaxWidth;
	//max-width: 1500px;
	margin: 0 auto;

	&.hide-b-s {
		@include below($m) {
			display: none;
		}
	}
}

// Footer
footer {
	width: 100%;
	//height: 416px;
	display: block;
	margin: 120px auto 0;
	//padding: 0 50px;
	position: relative;

	@include below($s) {
		margin-top: 40px;
	}
}

.footer-content {
	background: #F4F4F4;
	height: 416px;
	width: 100%;
	display: block;
	margin: 0 auto;
	padding: 50px 0;
}

.footer-contact {
	text-align: center;
	margin: 0 auto;

	.uppercase {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 5px;
		display: block;
		color: $darkBlue;
	}
}

.footer-social {
	position: absolute;
	bottom: 50px;
	left: 50px;
	height: 40px;

	@include below($m) {
		left: 30px;
	}

	@include below($s) {
		width: 100%;
		left: 0; 
		text-align: center;
	}

	.uppercase {
		font-size: 14px;
	}

	a {
		text-decoration: none;
		margin-left: 15px;
		display: inline-block;
		height: 100%;
		position: relative;
		bottom: -10px;
		transition: opacity .2s ease;

		img {
			display: inline-block;
		}

		&:hover {
			opacity: .7;
		}
	}
}

.footer-links {
	position: absolute;
	bottom: 50px;
	right: 50px;

	@include below($m) {
		right: 30px;
	}

	@include below($s) {
		position: relative;
		right: auto;
		bottom: auto;
		text-align: center;
		margin: 40px 0;
	}

	.footer-menu {
		list-style: none;

		li {
			display: inline-block;
		}
	}

	a {
		color: $black;
		text-decoration: none;
		font-family: $monoFont;
		font-size: 14px;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-weight: 500;
		opacity: 1;
		transition: opacity .2s ease;
		margin-left: 20px;
		color: $darkBlue;

		&:hover {
			opacity: .5;
		}

		@include below($s) {
			margin: 0 10px;
		}
	}
}

// Page intro
.regular-intro {
	width: 100%;
	max-width: $contentMaxWidth;
	//max-width: 1500px;
	margin: 60px auto 40px;
	display: block;

	@include below($s) {
		margin-top: 40px;
	}

	&.privacy-intro {
		.title-xl {
			//width: 70%;
			//margin: 0 auto;
		}
	}

	.title-xl {
		margin-bottom: 40px;
		width: 100%;
		//max-width: 780px;

		&.no-marg-b {
			margin-bottom: 0;
		}
	}

	.uppercase {
		margin-bottom: 15px;
		display: block;
	}

	.excerpt-p {
		width: 100%;
		max-width: 780px;
	}
}

// Scroll button
// Arrow up
.scroll-top-btn {
    width: 50px;
    height: 50px;
    border: 3px solid $darkBlue;
    background: $lightBlue;
    position: fixed;
    bottom: 40px;
    right: 40px;
    border-radius: 50px;
    opacity: 0;
    bottom: 20px;
    transition: opacity .3s ease, bottom .3s ease;

    img {
        @include align-both(absolute);
        margin-top: -2px;
    }

    &.is-visible {
        opacity: 1;
        bottom: 40px;
    }

    &.is-bottom {
    	bottom: 80px;
    }

    @include below($m) {
    	bottom: 20px;
    	right: 20px;

    	&.is-visible {
    		bottom: 20px;
    	}

    	&.is-bottom {
    		bottom: 20px;
    	}
    }
}

// Components
@import 'components/burger';
@import 'components/header';
@import 'components/top-section';
@import 'components/intro-section';
@import 'components/list-section';
@import 'components/ff-section';
@import 'components/intro-section-grid';
@import 'archive';
@import 'article';
@import 'components/contact-page';
.main-top-section {
	width: 100%;
	//max-width: 1440px;
	margin: 0 auto;
	max-height: 750px;
	display: block;
	height: 80vh;
	background-repeat: no-repeat;
	background-position: center center;
	//background-position: center bottom;
	background-size: cover;
	position: relative;
}

.top-hero {
	position: absolute;
	bottom: 50px;
	left: 50px;
	width: 100%;
	max-width: 570px;
	//@include align-both(absolute);
	//text-align: center;
	width: 50%;
	top: 0;
	left: 0;
	bottom: auto;
	background: rgba(255, 255, 255, .8);
	padding: 50px;
	height: 100%;
	max-width: 50%;

	@include below($m) {
		max-width: 100%;
		width: 100%;
		padding: 30px;
	}

	@include below($s) {
		padding: 20px;
	}

	.top-hero-inside {
		position: absolute;
		bottom: 50px;
		left: 50px;
		padding-right: 50px;

		@include below($m) {
			padding-right: 30px;
			bottom: 30px;
			left: 30px;
		}

		@include below($s) {
			padding-right: 20px;
			bottom: 20px;
			left: 20px;
		}
	}

	.title-xl {
		margin-bottom: 20px;

		@include below($s) {
			font-size: 30px;
			line-height: 40px;
		}
	}

	@include below($m) {
		//left: 30px;
		//bottom: 30px;
	}

	@include below($s) {
		@include vertical-align(absolute);
		bottom: auto;
		left: auto;
		padding: 20px;
	}
}

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, .4);
	//display: none;
	//background: white;
}